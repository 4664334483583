<style scoped>
form.upload {
  padding: 2rem;
}
.v-icon.v-icon {
  color: #707070;
}
div.col.col-12.wrapper {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}
.wrapper:has(:not(.v-chip)) i.v-icon.notranslate.mdi.mdi-information {
  height: 3.5rem;
}
.wrapper:has(.v-chip) i.v-icon.notranslate.mdi.mdi-information {
  height: 4.5rem;
}
.row + .row {
  margin-top: 0rem;
}
.page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.card-display,
.alert-error {
  width: 100%;
  max-width: 900px;
}
</style>
<style>
div.v-input.file-upload.error--text div.v-input__slot fieldset,
div.v-input.file-upload.v-input--is-label-active div.v-input__slot fieldset {
  border-style: solid;
}
div.v-input.file-upload div.v-input__slot fieldset {
  border-style: dashed;
}
div.v-input.file-upload.v-input--is-label-active div.v-input__slot fieldset {
  background: rgba(59, 59, 59, 0.06);
}
div.v-input.file-upload.theme--light.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  background-color: white;
}
@media screen and (max-width: 880px) {
  form.v-form:not(.edit-form) {
    margin: 0rem 1rem !important;
  }
  .third-row-container {
    display: flex;
    flex-direction: column;
  }
  .third-row-container div.col.col-6 {
    max-width: 100% !important;
  }
  .third-row-container div.col.col-6:first-child {
    padding-bottom: 0px;
  }
  h1 {
    font-size: 1.8rem;
  }
}
</style>
<template>
  <v-container fluid class="page">
    <AccountPendingAlert v-if="pending" />
    <v-alert
      v-if="error"
      text
      elevation="0"
      outlined
      prominent
      rounded
      class="alert-error"
      type="error"
      icon="mdi-cloud-alert"
    >
      {{ error }}
    </v-alert>
    <v-card class="card-display">
      <v-card-title class="header">Upload New Composition</v-card-title>
      <v-form
        @submit.prevent="submit"
        ref="uploadForm"
        lazy-validation
        class="upload"
      >
        <v-col
          cols="12"
          v-cloak
          @drop.prevent="dropScoreFile"
          @dragover.prevent
        >
          <v-row class="text-center">
            <v-file-input
              class="file-upload"
              v-model="scoreFile"
              :rules="scoreFileValidation"
              label="Score Upload (PDF)"
              color="secondary"
              placeholder="Score Upload (PDF)"
              prepend-icon=""
              append-icon="mdi-file-pdf-box"
              :show-size="1000"
              :persistent-hint="true"
              counter-size-string="300 MB Limit"
              counter
              outlined
              filled
              required
              :disabled="uploadingFiles"
              elevation="0"
              accept="application/pdf"
            >
              <template v-slot:selection="{ text }">
                <v-chip
                  label
                  :color="validSize(scoreFile.size) ? 'primary' : 'error'"
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input></v-row
          >
        </v-col>
        <v-row class="text-center">
          <v-col
            cols="12"
            v-cloak
            @drop.prevent="handleMusicFiles($event.dataTransfer.files)"
            @dragover.prevent
          >
            <v-file-input
              class="file-upload"
              :value="musicFiles"
              counter
              :rules="musicFilesValidation"
              label="Sheet Music Upload (PDFs)"
              color="secondary"
              multiple
              placeholder="Sheet Music Upload (PDFs)"
              prepend-icon=""
              append-icon="mdi-file-pdf-box"
              outlined
              :show-size="1000"
              filled
              :hint="'Optional'"
              :persistent-hint="true"
              :disabled="uploadingFiles"
              accept="application/pdf"
              counter-size-string="300 MB Limit Per File"
              @change="handleMusicFiles"
              @click:clear="musicFiles = []"
            >
              <template v-slot:selection="{ text, index }">
                <v-chip
                  label
                  :color="
                    validSize(musicFiles[index].size) ? 'primary' : 'error'
                  "
                  close
                  close-icon="mdi-close-box"
                  @click:close="deleteMusicFileItem(musicFiles[index])"
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
        </v-row>
        <v-row class="text-center third-row-container">
          <v-col
            cols="6"
            v-cloak
            @drop.prevent="dropCoverFile"
            @dragover.prevent
          >
            <v-file-input
              class="file-upload"
              v-model="coverPage"
              counter
              :rules="coverPageValidation"
              label="Web Thumbnail (JPG)"
              color="secondary"
              placeholder="Web Thumbnail (JPG)"
              prepend-icon=""
              append-icon="mdi-image-plus"
              outlined
              :show-size="1000"
              filled
              counter-size-string="300 MB Limit"
              :disabled="uploadingFiles"
              :hint="'Optional'"
              :persistent-hint="true"
              accept="image/jpeg"
            >
              <template v-slot:selection="{ text }">
                <v-chip
                  label
                  :color="validSize(coverPage.size) ? 'primary' : 'error'"
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
          <v-col
            cols="6"
            v-cloak
            @drop.prevent="dropAudioFile"
            @dragover.prevent
          >
            <v-file-input
              class="file-upload"
              v-model="previewAudio"
              counter
              :rules="previewAudioValidation"
              label="Preview Audio (MP3)"
              color="secondary"
              placeholder="Preview Audio (MP3)"
              prepend-icon=""
              append-icon="mdi-music"
              outlined
              counter-size-string="300 MB Limit"
              :show-size="1000"
              filled
              :disabled="uploadingFiles"
              :hint="'Optional'"
              :persistent-hint="true"
              accept="audio/mpeg"
            >
              <template v-slot:selection="{ text }">
                <v-chip
                  label
                  :color="validSize(previewAudio.size) ? 'primary' : 'error'"
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="wrapper"
            cols="12"
            v-cloak
            @drop.prevent="dropWrapperFile"
            @dragover.prevent
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-information</v-icon></template
              >
              <span
                >A wrapper is a protective cardstock cover or jacket that wraps
                arround the Score and Parts.</span
              >
            </v-tooltip>
            <v-file-input
              class="file-upload"
              v-model="wrapperFile"
              counter
              :rules="wrapperFileValidation"
              label="Wrapper/Folio (PDF)"
              color="secondary"
              placeholder="Wrapper/Folio (PDF)"
              prepend-icon=""
              append-icon="mdi-file-pdf-box"
              outlined
              :show-size="1000"
              filled
              :disabled="uploadingFiles"
              counter-size-string="300 MB Limit"
              :hint="'Optional'"
              :persistent-hint="true"
              accept="application/pdf"
            >
              <template v-slot:selection="{ text }">
                <v-chip
                  label
                  :color="validSize(wrapperFile.size) ? 'primary' : 'error'"
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center justify-center">
          <v-col cols="4" style="max-width: max-content">
            <v-btn
              type="submit"
              color="lightGold"
              dark
              class="px-14"
              elevation="0"
              rounded
              :loading="uploadingFiles"
            >
              Upload
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { v4 } from "uuid";
import path from "path";
const MYSCORE_BACKEND_ROOT = process.env.VUE_APP_MYSCORE_BACKEND || "";
const DEFAULT_UPLOAD_ERROR_MESSAGE =
  "There was a problem creating your upload. Please try again later and contact support if this continues.";

import { AuthenticatedFetch as composerFetch } from "../helpers/Requests";
import CloudFileUtils from "../helpers/CloudFileUtils";
import AccountPendingAlert from "./AccountPendingAlert.vue";

export default {
  name: "Uploader",
  components: {
    AccountPendingAlert,
  },
  props: {
    pending: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    validSize: function () {
      return (size) => {
        return size <= this.fileLimit;
      };
    },
  },
  data: function () {
    return {
      // iFrameLoaded: false,
      myScoreToken: localStorage.getItem("myscore-service-composer-auth"),
      uploadToken: v4(),
      scoreFile: null,
      musicFiles: [],
      coverPage: null,
      error: null,
      previewAudio: null,
      wrapperFile: null,
      uploadingFiles: false,
      fileLimit: 300000000,
      scoreFileValidation: [
        (file) => {
          if (!file) return "Must have one file.";
          if (file.type !== "application/pdf") {
            return "Must be a PDF file.";
          }
          if (file.size > this.fileLimit) {
            return "File size must be less than 300MB.";
          }
          return true;
        },
      ],
      musicFilesValidation: [
        (files) => {
          for (let i = 0; i < files.length; i++) {
            if (files[i].type !== "application/pdf") {
              return "Must be PDF files.";
            }
            if (files[i].size > this.fileLimit) {
              return "Each file size must be less than 300MB.";
            }
          }
          return true;
        },
      ],
      coverPageValidation: [
        (file) => {
          // Cover page not required
          if (!file) return true;

          if (file.type !== "image/jpeg") {
            return "Must be JPG file.";
          }
          if (file.size > this.fileLimit) {
            return "File size must be less than 300MB.";
          }
          return true;
        },
      ],
      previewAudioValidation: [
        (file) => {
          // audio not required
          if (!file) return true;

          if (file.type !== "audio/mpeg") {
            return "Must be MP3 file.";
          }
          if (file.size > this.fileLimit) {
            return "File size must be less than 300MB.";
          }
          return true;
        },
      ],
      wrapperFileValidation: [
        (file) => {
          // wrapper not required
          if (!file) return true;

          if (!file || file.type !== "application/pdf") {
            return "Must be PDF file.";
          }
          if (file.size > this.fileLimit) {
            return "File size must be less than 300MB.";
          }
          return true;
        },
      ],
      // uploadFormID: process.env.VUE_APP_MYSCORE_UPLOAD_FORM_ID,
    };
  },
  methods: {
    deleteMusicFileItem: function (selected) {
      const index = this.musicFiles.indexOf(selected);
      if (index !== -1) {
        this.musicFiles.splice(index, 1);
      }
    },
    dropScoreFile: function (e) {
      this.scoreFile = e.dataTransfer.files[0];
    },
    handleMusicFiles: function (files) {
      const newFiles = Array.from(files);
      const uniqueFiles = this.removeDuplicates([
        ...this.musicFiles,
        ...newFiles,
      ]);
      this.musicFiles = uniqueFiles;
    },
    removeDuplicates: function (files) {
      const uniqueSet = new Set(files.map((file) => file.name));
      return Array.from(uniqueSet, (fileName) =>
        files.find((file) => file.name === fileName)
      );
    },
    dropCoverFile: function (e) {
      this.coverPage = e.dataTransfer.files[0];
    },
    dropAudioFile: function (e) {
      this.previewAudio = e.dataTransfer.files[0];
    },
    dropWrapperFile: function (e) {
      this.wrapperFile = e.dataTransfer.files[0];
    },
    submit: async function () {
      this.uploadToken = v4();
      this.error = null;

      if (this.$refs.uploadForm.validate()) {
        this.uploadingFiles = true;

        // Create the upload job via a PUT request to /upload
        const res = await composerFetch(
          `${MYSCORE_BACKEND_ROOT}/api/v1/upload`,
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ token: this.uploadToken }),
          }
        );

        const { upload: uploadRes } = await res.json();
        if (uploadRes.status != "CREATED") {
          this.uploadingFiles = false;
          this.error = DEFAULT_UPLOAD_ERROR_MESSAGE;
          return;
        }

        // Upload each file via a PUT request to /upload/:token/file/:filename
        for (let i = 0; i < this.musicFiles.length; i++) {
          const file = this.musicFiles[i];
          let fileWithoutExt = file.name
            .replace(/\.[^/.]+$/, "")
            .replace(/[^a-zA-Z0-9_ -]/g, " ")
            .trim();

          try {
            await CloudFileUtils.upload(
              `${MYSCORE_BACKEND_ROOT}/api/v1/upload/${
                this.uploadToken
              }/file/${encodeURI(fileWithoutExt)}`,
              file
            );
          } catch (err) {
            this.uploadingFiles = false;
            this.error = DEFAULT_UPLOAD_ERROR_MESSAGE;
            return;
          }
        }

        // Score upload
        if (this.scoreFile) {
          let fileWithoutExt = this.scoreFile.name
            .replace(/\.[^/.]+$/, "")
            .replace(/[^a-zA-Z0-9_ -]/g, " ")
            .trim();
          if (!fileWithoutExt.toLowerCase().includes("score")) {
            fileWithoutExt += " Score";
          }
          try {
            await CloudFileUtils.upload(
              `${MYSCORE_BACKEND_ROOT}/api/v1/upload/${
                this.uploadToken
              }/file/${encodeURI(fileWithoutExt)}`,
              this.scoreFile
            );
          } catch (err) {
            this.uploadingFiles = false;
            this.error = DEFAULT_UPLOAD_ERROR_MESSAGE;
            return;
          }
        }

        // If there is a cover page, upload it
        if (this.coverPage) {
          let fileWithoutExt = this.coverPage.name
            .replace(/\.[^/.]+$/, "")
            .replace(/[^a-zA-Z0-9_ -]/g, " ")
            .trim();

          try {
            await CloudFileUtils.upload(
              `${MYSCORE_BACKEND_ROOT}/api/v1/upload/${
                this.uploadToken
              }/metaFile/${encodeURI(fileWithoutExt)}`,
              this.coverPage
            );
          } catch (err) {
            this.uploadingFiles = false;
            this.error = DEFAULT_UPLOAD_ERROR_MESSAGE;
            return;
          }
        }

        // If there is a preview audio, upload it
        if (this.previewAudio) {
          let fileWithoutExt = this.previewAudio.name
            .replace(/\.[^/.]+$/, "")
            .replace(/[^a-zA-Z0-9_ -]/g, " ")
            .trim();

          try {
            await CloudFileUtils.upload(
              `${MYSCORE_BACKEND_ROOT}/api/v1/upload/${
                this.uploadToken
              }/metaFile/${encodeURI(fileWithoutExt)}`,
              this.previewAudio
            );
          } catch (err) {
            this.uploadingFiles = false;
            this.error = DEFAULT_UPLOAD_ERROR_MESSAGE;
            return;
          }
        }

        // If there is a wrapper file, upload it
        if (this.wrapperFile) {
          let fileWithoutExt = this.wrapperFile.name
            .replace(/\.[^/.]+$/, "")
            .replace(/[^a-zA-Z0-9_ -]/g, " ")
            .trim();

          try {
            await CloudFileUtils.upload(
              `${MYSCORE_BACKEND_ROOT}/api/v1/upload/${
                this.uploadToken
              }/metaFile/${encodeURI(fileWithoutExt)}`,
              this.wrapperFile
            );
          } catch (err) {
            this.uploadingFiles = false;
            this.error = DEFAULT_UPLOAD_ERROR_MESSAGE;
            return;
          }
        }

        // Call the /upload/:token/start endpoint to start processing the upload
        await composerFetch(
          `${MYSCORE_BACKEND_ROOT}/api/v1/upload/${this.uploadToken}/start`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        // Redirect to the upload page
        window.location.href = this.getRedirectOnSubmit();
      }
    },
    getRedirectOnSubmit: function () {
      let origin = window.location.origin;
      let completePath = "/";

      if (origin.startsWith("https://marcus-devops")) {
        completePath = window.location.pathname;
      }

      if (
        origin.startsWith("http://localhost") ||
        origin.startsWith("https://marcus-devops")
      ) {
        completePath = path.join(completePath, "/#");
      }

      return origin + path.join(completePath, "/upload/" + this.uploadToken);
    },
  },
};
</script>
