import Vue from "vue";
import router from "./plugins/router";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Vue.prototype.$myscore_public_path =
  process.env.VUE_APP_MYSCORE_PUBLIC_PATH || "/";

declare global {
  interface Window {
    redirectToMyScore: () => void;
    removeSpecialCharacters: (text: string) => string;
    FreshworksWidget: {
      (...args: any[]): void;
      q: any[];
    };
  }
}

window.removeSpecialCharacters = function (text: string): string {
  const withoutTags = text.replace(/<[^>]+>/g, "");
  const withoutSpecialChars = withoutTags.replace(/[^\w\s.!?]/gi, "");
  return withoutSpecialChars;
};

function checkAuthExpiry() {
  // This function returns if the auth token is NOT expired.

  const auth = localStorage.getItem("myscore-service-composer-auth");
  if (auth && auth.split(".").length === 3) {
    try {
      const payload = JSON.parse(
        Buffer.from(auth.split(".")[1], "base64").toString("utf8")
      );
      if (payload.exp) {
        // Using exp, which is milliseconds since epoch, check if it's expired.
        if (payload.exp >= Date.now()) {
          return;
        }
      }
    } catch (e) {
      console.log(e);
    }

    localStorage.removeItem("myscore-service-composer-auth");
  }

  // If we get here, the auth token is expired.
  // Redirect to the login page.
  window.redirectToMyScore();
}

window.redirectToMyScore = function () {
  window.location.href =
    process.env.VUE_APP_MYSCORE_LOGIN_PAGE ||
    "https://auth.jwpepper.com/v1/authorize?service=my-score"; // Redirect to the SSO login page. *** VERY IMPORTANT NOTE *** Be sure that production Login Helper is deployed before deploying this.
};

// Check for a query parameter called auth. If it exists, save it to local storage
// and redirect to the same page without the query parameter.
function checkForAuth(urlParams: URLSearchParams) {
  if (urlParams.has("auth")) {
    localStorage.setItem(
      "myscore-service-composer-auth",
      urlParams.get("auth") as string
    );
    // Redirect to the same page without any query parameters.
    window.location.href = window.location.href.split("?")[0];
  }
}

// Check for a regular query parameter, and a query parameter after the hash.
const urlParams = new URLSearchParams(window.location.search);
checkForAuth(urlParams);
const urlWithoutHash = window.location.href.replace("#/", "");
checkForAuth(new URLSearchParams(new URL(urlWithoutHash).search));
checkAuthExpiry();
console.log("Running at " + (process.env.VUE_APP_MYSCORE_PUBLIC_PATH || "/"));

Vue.config.productionTip = false;

if (process.env.NODE_ENV === "production") {
  console.log("Initializing Sentry");
  Sentry.init({
    Vue,
    dsn: "https://f779539db3dc4f07a5733a06a55ce352@o402464.ingest.sentry.io/6148122",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          "localhost",
          "marcus-devops.jwpepper.com",
          Vue.prototype.$myscore_public_path,
        ],
      }),
    ],
  });
} else {
  console.log("Not using Sentry as we are not in production.");
}

export const Global = new Vue({
  data: {
    uploadTitle: "Untitled",
    stepNum: 0,
    userSorted: false,
    allowedNavigation: true,
  },
});

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
